

.wp-content {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    margin-bottom: 30px;

    p {
        margin-bottom: 8px;
    }

    ul {
        margin-bottom: 8px;
    }

    a {
        color: $textColor;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .is-layout-flex {
        display: flex;
        gap: 5vh;

        @include tablet {
            flex-direction: column;
        }
    }

    .is-layout-flow {
        width: 50%;

        @include tablet {
            width: 100%;
        }
    }

    .wp-block-outermost-icon-block {
        width: 60px !important;
    }

    .icon-container {
        width: 50px !important;
        margin-right: 10px;
    }

    .wp-container-1 {
        display: flex;
        align-items: center;
    }
}