@import "../../style.scss";

.notfound {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 40px;

    h2 {
        font-size: 50px;
        margin: 0 auto 2% auto;
        width: -webkit-fill-available;
        max-width: 1400px;
    }

    .container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0 auto;
        padding: 0 5%;
        width: -webkit-fill-available;
        max-width: 1400px;
        gap: 10px;
    }
}