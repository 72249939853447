@import "../../style.scss";
@import "../../wp-style.scss";

.contact {
    padding: 100px 0;

    .container {
        margin: 0 auto;
        padding: 0 5%;
        max-width: 1400px;

        .header {
            display: flex;
            align-items: center;
            margin-bottom: 5%;
            gap: 20px;

            svg {
                color: $textColor;
                font-size: 40px;
                cursor: pointer;
            }

            h1 {
                font-size: 50px;
                cursor: default;
                user-select: none;
            }
        }

        .inputsContact {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;
            gap: 10px;

            input,
            textArea {
                padding: 12px 24px;
                width: -webkit-fill-available;
                outline: none;
                background: rgba($textColor, 0.1);
                border: none;
                color: $textColor;
                transition: 0.2s ease-in-out;

                &:hover {
                    background: rgba($textColor, 0.2);
                }

                &:focus {
                    background: rgba($textColor, 0.2);
                }
            }

            input {
                max-width: 500px;
            }

            textArea {
                resize: vertical;
            }

            button {
                border: 4px solid $textColor;
                background: none;
                color: $textColor;
                transform: scale(1);
                padding: 12px 24px;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
                width: fit-content;
                cursor: pointer;
                transition: 0.3s;
                z-index: 2;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background: $textColor;
                    color: $bg;
                    transition: 0.3s;
                    z-index: -1;
                }

                &:hover {
                    color: $bg;

                    &::after {
                        width: 100%;
                    }
                }

                &:disabled {
                    opacity: 0.5;
                    color: $textColor;
                    cursor: initial;

                    &::after {
                        width: 0;
                    }
                }
            }

            .senden {
                display: flex;
                gap: 20px;

                .gesendet {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}