$textColor: #ffffff;
$bg: #030303;
$accent: #006ec8;
$textColorHover: #d5d5d5;

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.site {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: $bg;
  color: $textColor;
}

p {
  font-size: 20px;
  margin-bottom: 12px;
}

input {
  font-size: 20px;
}

textarea {
  font-size: 20px;
}

.myModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  z-index: 200;
}

.loading {
  display: flex;
  justify-content: center;
  height: 100px;
  margin: 5%;

  &::after {
    content: "";
    position: absolute;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: transparent;
    border: 4px solid $textColor;
    border-top: 4px solid $accent;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 760px) {
    @content;
  }
}