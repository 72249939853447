@import "../../style.scss";

.view {
    .header {
        display: flex;
        align-items: center;
        position: relative;
        height: 50vh;
        background: rgba($textColor, 0.1);

        .container {
            display: flex;
            flex-direction: column;
            width: -webkit-fill-available;

            .postTyp {
                opacity: 0.5;
                font-size: 20px;
            }

            .postKategorie {
                font-size: 25px;
            }

            h3 {
                font-size: 60px;
                text-transform: uppercase;

                @include tablet {
                    font-size: 50px;
                }

                @include mobile {
                    font-size: 40px;
                }
            }

            .postDatum {
                font-size: 20px;
            }

            .ReactAudioPlayer {
                position: absolute;
                bottom: 0;
            }
        }
    }

    .inhalt {
        margin: 5% 0;

        .container {

            .infos {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin: 20px 0 50px 0;

                .grade, .besetzung, .pdf, .link {
                    display: flex;
                    align-items: center;
                    background: rgba($textColor, 0.1);
                    padding: 12px 24px;
                    width: fit-content;
                    cursor: default;

                    .left {
                        opacity: 0.5;
                    }

                    .right {
                        margin-left: 10px;
                    }
                }

                .pdf, .link {
                    background: $accent;
                    text-decoration: none;
                    color: $textColor;
                    cursor: pointer;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    .container {
        margin: 0 auto;
        padding: 0 5%;
        max-width: 1400px;
    }
}