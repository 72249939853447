@import "../../style.scss";
@import "../../wp-style.scss";

.news {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 40px;

    .select {
        display: flex;
        align-items: center;
        padding: 0 5%;
        gap: 30px;
        margin-bottom: 5%;
        cursor: pointer;

        @include tablet {
            flex-direction: column;
            align-items: flex-start;
        }

        a {
            font-size: 40px;
            text-decoration: none;
            font-weight: bold;
            color: $textColor;
            opacity: 0.5;
            transition: 0.2s ease-in-out;

            &:hover {
                opacity: 0.75;
            }
        }

        a.selected {
            opacity: 1;
            font-weight: bold;
        }
    }

    h2 {
        font-size: 50px;
        padding: 0;
        width: fit-content;
        max-width: 1400px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        padding: 0 5%;
        width: -webkit-fill-available;
        max-width: 1400px;
        gap: 10px;

        .postContent {
            display: flex;
            flex-direction: column;
            position: relative;
            background: $textColor;
            cursor: pointer;
            text-decoration: none;
            color: $textColor;

            &:hover {
                z-index: 10;
            }

            .inline {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                position: relative;
                overflow: hidden;
                background: rgba($bg, 0.90);
                height: 200px;
                width: 200px;
                padding: 40px;
                z-index: 3;
                transition: 0.2s ease-in-out;

                .postTyp {
                    font-size: 16px;
                    opacity: 0.5;
                }

                .postKategorie {
                    font-size: 20px;
                }

                h3 {
                    font-size: 30px;
                    text-transform: uppercase;
                    word-wrap: break-word;
                    -webkit-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto;
                }

                .postDatum {
                    opacity: 0.5;
                }

                .postBg {
                    position: absolute;
                    left: -30px;
                    opacity: 0.1;
                    bottom: -90px;
                    font-size: 300px;
                    font-weight: bold;
                }
            }

            .content {
                display: flex;
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                transition-duration: 0.2s;

                .raster {
                    opacity: 0;
                    width: 100%;
                }
            }

            &:hover .content {
                position: absolute;
                left: -10px;
                top: -10px;
                background: $textColor;
                width: calc(100% + 20px);
                z-index: 2;
                padding: 300px 0 0;
                transition-duration: 0.2s;
                transition-delay: 0.2s;

                .raster {
                    display: grid;
                    background: rgba($accent, 0.4);
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    width: 100%;
                    opacity: 1;
                    transition-delay: 0.4s;
                    transition-duration: 0.2s;


                    .element {
                        text-align: center;
                        width: auto;
                        padding: 15px;
                        background: $accent;
                        color: $textColor;
                    }

                    .element.empty {
                        background: transparent;
                        color: $textColor;
                        opacity: 0.4;
                    }
                }
            }

            .open {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                color: $bg;
                width: 0;
                padding: 40px 0;
                overflow-x: hidden;
                z-index: 3;
                visibility: 0;
                transition: 0.2s ease-in-out;
            }

            &:hover {

                .open {
                    display: grid;
                    width: 280px;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 10px;
                    visibility: 1;

                    .kachel {
                        background: rgba($bg, 0.9);
                        color: $textColor;
                        padding: 20px;

                        svg {
                            width: inherit;
                            height: inherit;
                        }
                    }
                }

                .inline {
                    transform: scale(0.95);
                }
            }
        }
    }
}