@import "../../style.scss";
@import "../../wp-style.scss";

.home {

    .container {
        margin: 0 auto;
        padding: 0 5%;
        max-width: 1400px;
    }

    .header {
        display: flex;
        height: 100vh;
        background-size: cover !important;

        .container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            width: 100%;

            .top {
                h1 {
                    font-size: 100px;
                    color: $textColor;
                    line-height: 120px;

                    @include tablet {
                        font-size: 50px;
                        line-height: 60px;
                    }
                }

                span {
                    margin-top: 20px;
                    font-size: 30px;
                    opacity: 0.5;
                }
            }

            .bottom {
                display: flex;
                flex-direction: column;

                span {
                    border-left: 5px solid $accent;
                    padding-left: 30px;
                    font-size: 30px;
                    margin-bottom: 30px;
                }

                .links {
                    display: flex;
                    gap: 20px;

                    @include tablet {
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .about {
        padding: 10% 0;

        h2 {
            margin-bottom: 5%;
            font-size: 50px;
        }

        .bioContent {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;

            .left {
                display: flex;
                align-items: flex-end;
                gap: 20px;
                flex-direction: column;

                .top {
                    width: 350px;
                    max-width: 100%;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.01);
                    }
                }

                .bottom {
                    max-height: 300px;
                    height: auto;
                    max-width: 100%;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.01);
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                overflow: hidden;
                gap: 20px;

                @include mobile {
                    align-items: normal;
                }

                p {
                    max-width: 500px;
                }

                img {
                    width: 500px;
                    max-width: 100%;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.01);
                    }
                }
            }

            @include tablet {
                flex-direction: column;
            }
        }
    }

    .post {
        padding-bottom: 10%;

        h2 {
            margin-bottom: 5%;
            font-size: 50px;
        }

        button {
            margin: 20px 0;
        }
    }

    button {
        border: 4px solid $textColor;
        background: none;
        color: $textColor;
        transform: scale(1);
        padding: 12px 24px;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        width: fit-content;
        cursor: pointer;
        transition: 0.3s;
        z-index: 2;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: $textColor;
            color: $bg;
            transition: 0.3s;
            z-index: -1;
        }

        &:hover {
            color: $bg;

            &::after {
                width: 100%;
            }
        }
    }
}