@import "../../style.scss";

.footer {
    color: $textColor;
    margin-top: 50px;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 5%;
        background-color: rgba($textColor, 0.1);

        .contactBanner {
            display: flex;
            position: relative;
            justify-content: space-evenly;
            width: -webkit-fill-available;
            max-width: 1400px;
            padding: 40px;
            background: $accent;
            transform: translate(0, -50px);
            gap: 30px;

            .contact {
                position: absolute;
                top: -20px;
                left: 40px;
                background: $textColor;
                color: $accent;
                padding: 12px 24px;
                text-transform: uppercase;
                font-weight: bold;
                cursor: default;

                @include tablet {
                    left: 20px;
                }
            }

            .telBox,
            .emailBox {
                display: flex;
                gap: 20px;
                cursor: pointer;

                svg {
                    background: $textColor;
                    fill: $accent;
                    height: 30px;
                    width: 30px;
                    padding: 20px;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .desc {
                        opacity: 0.5;
                    }

                    .value {
                        font-size: 20px;
                    }
                }
            }

            @include tablet {
                flex-direction: column;
                padding: 20px;
                padding-top: 40px;
            }
        }

        .bottom {
            display: flex;
            width: 100%;
            max-width: 1400px;
            justify-content: space-between;
            align-items: center;

            a {
                color: $textColor;
                text-decoration: none;
                transition: 0.2s ease-in-out;

                &:hover {
                    opacity: 0.5;
                }
            }

            .right {
                display: flex;
                gap: 20px;
            }

            @include tablet {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}