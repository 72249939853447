@import "../../style.scss";

.posts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .postContent {
        background: $textColor;
        cursor: pointer;
        text-decoration: none;
        color: $textColor;

        .inline {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            overflow: hidden;
            background: rgba($bg, 0.90);
            height: 200px;
            width: 200px;
            padding: 40px;
            z-index: 3;
            transition: 0.2s ease-in-out;

            .postTyp {
                font-size: 16px;
                opacity: 0.5;
            }

            .postKategorie {
                font-size: 20px;
            }

            h3 {
                font-size: 30px;
                text-transform: uppercase;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }

            .postDatum {
                opacity: 0.5;
            }

            .postBg {
                position: absolute;
                left: -30px;
                opacity: 0.1;
                bottom: -90px;
                font-size: 300px;
                font-weight: bold;
            }

            &:hover {
                transform: scale(0.95);
            }
        }
    }
}