@import "../../style.scss";
@import "../../wp-style.scss";

.datenschutz {
    padding: 100px 0;

    .container {
        margin: 0 auto;
        padding: 0 5%;
        max-width: 1400px;

        h2 {
            margin-bottom: 5%;
            font-size: 50px;
        }

        .datenschutzContent {
            h3 {
                font-size: 30px;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 20px;
            }

            ol {
                li {
                    font-size: 30px;
                    font-weight: bold;

                    h4 {
                        font-size: 25px;
                        margin-bottom: 10px;
                    }

                    h5 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: initial;
                        font-weight: initial;

                        bold {
                            font-weight: bold;
                        }
                    }

                    ul {
                        margin-bottom: 20px;
                        li {
                            font-size: initial;
                            font-weight: initial;
                        }
                    }
                }
            }
        }
    }
}