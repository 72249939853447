@import "../../style.scss";
@import "../../wp-style.scss";

.projekte {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 40px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 5vh;
        width: -webkit-fill-available;
        margin: 0 auto;
        padding: 0 5%;
        max-width: 1400px;

        h2 {
            margin-bottom: 5%;
            font-size: 50px;
        }

        h3 {
            color: $accent;
            margin-bottom: 20px;
        }

        .projektContent {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 5%;
            color: $textColor;
            text-decoration: none;

            .top {
                width: 100%;
            }

            .bottom {
                width: 100%;

                img {
                    width: 100% !important;
                }
            }
        }
    }
}