@import "../../style.scss";
@import "../../wp-style.scss";

.impressum {
    padding: 100px 0;

    .container {
        margin: 0 auto;
        padding: 0 5%;
        max-width: 1400px;

        h2 {
            margin-bottom: 5%;
            font-size: 50px;
        }

        .impressumContent {
            h3 {
                font-size: 30px;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 20px;
            }
        }
    }
}