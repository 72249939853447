@import "../../style.scss";

.navbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 5%;
    width: -webkit-fill-available;
    height: 60px;
    z-index: 30;

    .svgLogo {
        fill: $textColor;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        z-index: 40;

        &:hover {
            fill: $textColorHover;
        }
    }

    a {
        z-index: 40;
    }

    .right {
        display: flex;
        flex-direction: row;
        gap: 40px;
        z-index: 40;

        .openNav {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            gap: 7px;
            height: inherit;
            cursor: pointer;
            z-index: 40;

            .navLines {
                transition: 0.1s ease-in-out;
                background: $textColor;
                height: 2.5px;
                width: 50px;
            }

            .navLines:last-child {
                width: 30px;
            }
        }

        .openNav.active {
            .navLines {
                transform: rotate(45deg) translate(1px, 5px);
            }

            .navLines:last-child {
                width: 50px;
                transform: rotate(-45deg) translate(1px, -5px);
            }
        }
    }

    .nav {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        background-color: rgba($bg, 0.9);
        padding: 100px 5%;
        transition: 0.3s ease-in-out;

        .navigationWrapper {
            display: flex;
            width: -webkit-fill-available;
            opacity: 1;

            .list {
                display: flex;
                flex-direction: column;
                width: inherit;

                a {
                    text-align: center;
                    color: $textColor;
                    padding: 12px 24px;
                    font-size: 30px;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: bold;
                    transition: 0.2s;

                    &:hover {
                        color: $accent;
                    }

                    .active {
                        color: $accent;
                    }
                }

                a.active {
                    color: $accent;
                }
            }
        }
    }

    .nav.open {
        display: flex;
        height: 0;
        padding: 0 5%;

        .navigationWrapper {
            opacity: 0;
            display: none;
        }
    }
}