@import "../../style.scss";

.rhap_container {
    background-color: transparent;
    box-shadow: none;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;

}

.rhap_progress-container {
    position: absolute;
    bottom: 0;
    width: -webkit-fill-available;
    height: 5px;
    margin: 0;
}

.rhap_progress-filled {
    background: $accent;
}

.rhap_progress-indicator {
    background: $accent;
    box-shadow: none;
}

.rhap_progress-bar-show-download {
    background-color: transparent;
}

.rhap_download-progress {
    background-color: rgba($textColor, 0.1);
    border-radius: 0px;
}

.rhap_main {
    flex-direction: column-reverse;
}

.rhap_stacked .rhap_controls-section {
    margin: 0;
}

.rhap_time {
    color: $textColor;
    position: absolute;
    left: 5%;
    bottom: 30px;
}

.rhap_current-time {
    opacity: 0.5;
}

.rhap_total-time {
    margin-left: 60px;
}

.rhap_controls-section {
    position: absolute;
    right: 5%;
    bottom: 20px;

    .rhap_play-pause-button {
        display: flex;
        width: 150px;
        height: 150px;

        @include tablet {
            width: 70px;
            height: 70px;
        }

        svg {
            width: inherit;
            height: inherit;
            transition: 0.2s ease-in-out;

            path {
                fill: $accent;
            }

            &:hover {
                transform: scale(1.05);
            }
        }
    }
}

.rhap_additional-controls,
.rhap_rewind-button,
.rhap_forward-button,
.rhap_volume-controls {
    display: none;
}