@import "../../style.scss";
@import "../../wp-style.scss";

.biografie {
    padding: 100px 0;

    .container {
        margin: 0 auto;
        padding: 0 5%;
        max-width: 1400px;

        h2 {
            margin-bottom: 5%;
            font-size: 50px;
        }

        .bioContent {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;

            .left {
                display: flex;
                align-items: flex-end;
                gap: 20px;
                flex-direction: column;

                .top {
                    width: 350px;
                    max-width: 100%;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.01);
                    }
                }

                .bottom {
                    max-height: 300px;
                    max-width: 100%;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.01);
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 20px;

                p {
                    max-width: 500px;
                }

                img {
                    width: 500px;
                    max-width: 100%;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.01);
                    }
                }
            }

            @include tablet {
                flex-direction: column;
            }
        }
    }
}